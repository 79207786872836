import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

const DeleteDomainDialog = ({ open, domain, onClose, onDeleteDomain }) => {
    if (!domain) return null;

    const handleDelete = () => {
        onDeleteDomain(domain.id);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Подтвердить удаление</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Вы уверены, что хотите удалить домен <strong>{domain.domain_name}</strong>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleDelete} color="secondary" variant="contained">
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDomainDialog;
