import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Grid, IconButton, Card, CardContent, CardActions, Divider
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import API_BASE_URL from '../../../apiConfig';

const Cloudflare = ({ token }) => {
  const [accounts, setAccounts] = useState([]);
  const [newAccountName, setNewAccountName] = useState('');
  const [newAccountEmail, setNewAccountEmail] = useState('');
  const [newAccountApiKey, setNewAccountApiKey] = useState('');
  const [error, setError] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);

  useEffect(() => {
    fetchAccounts();
  }, [token]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cloudflare_accounts`, {
        headers: { Authorization: token }
      });
      setAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch accounts');
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddAccount = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/cloudflare_accounts`, {
        name: newAccountName,
        api_key: newAccountApiKey,
        email: newAccountEmail
      }, {
        headers: { Authorization: token }
      });
      fetchAccounts();
      handleCloseAddDialog();
      setNewAccountName('');
      setNewAccountEmail('');
      setNewAccountApiKey('');
    } catch (error) {
      setError('Failed to add account');
    }
  };

  const handleOpenEditDialog = (account) => {
    setEditingAccount(account);
    setNewAccountName(account.name);
    setNewAccountEmail(account.email);
    setNewAccountApiKey(account.api_key);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditingAccount(null);
  };

  const handleEditAccount = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/cloudflare_accounts/${editingAccount.id}`, {
        name: newAccountName,
        api_key: newAccountApiKey,
        email: newAccountEmail
      }, {
        headers: { Authorization: token }
      });
      fetchAccounts();
      handleCloseEditDialog();
    } catch (error) {
      setError('Failed to edit account');
    }
  };

  const handleDeleteAccount = async (accountId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/cloudflare_accounts/${accountId}`, {
        headers: { Authorization: token }
      });
      fetchAccounts();
    } catch (error) {
      setError('Failed to delete account');
    }
  };

  return (
      <Container>
        {error && <Typography color="error" align="center">{error}</Typography>}

        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
            Add Account
          </Button>
        </Box>

        {/* Display accounts in a grid of cards */}
        <Grid container spacing={2}>
          {accounts.map((account) => (
              <Grid item xs={12} sm={6} md={4} key={account.id}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {account.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Email:</strong> {account.email}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>API Key:</strong> {account.api_key}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>NS Servers:</strong> {account.ns_1 ? `${account.ns_1}, ${account.ns_2}` : 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Date Created:</strong> {new Date(account.created_at).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <IconButton color="primary" onClick={() => handleOpenEditDialog(account)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteAccount(account.id)}>
                      <Delete />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
          ))}
        </Grid>

        {/* Add Account Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>Add Cloudflare Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new Cloudflare account.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    label="Account Name"
                    value={newAccountName}
                    onChange={(e) => setNewAccountName(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="Email"
                    value={newAccountEmail}
                    onChange={(e) => setNewAccountEmail(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="API Key"
                    value={newAccountApiKey}
                    onChange={(e) => setNewAccountApiKey(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddAccount} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Account Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Edit Cloudflare Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Update the details of the Cloudflare account.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    label="Account Name"
                    value={newAccountName}
                    onChange={(e) => setNewAccountName(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="Email"
                    value={newAccountEmail}
                    onChange={(e) => setNewAccountEmail(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="API Key"
                    value={newAccountApiKey}
                    onChange={(e) => setNewAccountApiKey(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditAccount} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
};

export default Cloudflare;