import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Box, IconButton, Grid, useMediaQuery, useTheme, Hidden, Card, CardContent, CardActions
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import API_BASE_URL from '../../../apiConfig';

const Servers = ({ token }) => {
  const [servers, setServers] = useState([]);
  const [newServerName, setNewServerName] = useState('');
  const [newServerIP, setNewServerIP] = useState('');
  const [newServerUser, setNewServerUser] = useState('');
  const [newServerPassword, setNewServerPassword] = useState('');
  const [error, setError] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingServer, setEditingServer] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check for small screens

  useEffect(() => {
    fetchServers();
  }, [token]);

  const fetchServers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/servers`, {
        headers: { Authorization: token }
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddServer = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/servers`, {
        name: newServerName,
        ssh_host: newServerIP,
        ssh_user: newServerUser,
        ssh_password: newServerPassword
      }, {
        headers: { Authorization: token }
      });
      fetchServers();
      handleCloseAddDialog();
      setNewServerName('');
      setNewServerIP('');
      setNewServerUser('');
      setNewServerPassword('');
    } catch (error) {
      setError('Failed to add server');
    }
  };

  const handleOpenEditDialog = (server) => {
    setEditingServer(server);
    setNewServerName(server.name);
    setNewServerIP(server.ssh_host);
    setNewServerUser(server.ssh_user);
    setNewServerPassword(server.ssh_password);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditingServer(null);
  };

  const handleEditServer = async () => {
    try {
      await axios.put(`${API_BASE_URL}/api/servers/${editingServer.id}`, {
        name: newServerName,
        ssh_host: newServerIP,
        ssh_user: newServerUser,
        ssh_password: newServerPassword
      }, {
        headers: { Authorization: token }
      });
      fetchServers();
      handleCloseEditDialog();
    } catch (error) {
      setError('Failed to edit server');
    }
  };

  const handleDeleteServer = async (serverId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/servers/${serverId}`, {
        headers: { Authorization: token }
      });
      fetchServers();
    } catch (error) {
      setError('Failed to delete server');
    }
  };

  return (
      <Container>
        {error && <Typography color="error">{error}</Typography>}

        {/* Add Server Button */}
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
            Add Server
          </Button>
        </Box>

        {/* If it's a small screen, show cards, else show table */}
        <Hidden smDown>
          {/* Table for desktop */}
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servers.map((server) => (
                    <TableRow key={server.id}>
                      <TableCell>{server.name}</TableCell>
                      <TableCell>{server.ssh_host}</TableCell>
                      <TableCell>{server.ssh_user}</TableCell>
                      <TableCell>{server.ssh_password}</TableCell>
                      <TableCell>{new Date(server.created_at).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleOpenEditDialog(server)}>
                          <Edit />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => handleDeleteServer(server.id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>

        {/* Cards for mobile */}
        <Hidden mdUp>
          <Grid container spacing={2}>
            {servers.map((server) => (
                <Grid item xs={12} sm={6} md={4} key={server.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{server.name}</Typography>
                      <Typography variant="body2">IP Address: {server.ssh_host}</Typography>
                      <Typography variant="body2">User: {server.ssh_user}</Typography>
                      <Typography variant="body2">Password: {server.ssh_password}</Typography>
                      <Typography variant="body2">Created: {new Date(server.created_at).toLocaleDateString()}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton color="primary" onClick={() => handleOpenEditDialog(server)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteServer(server.id)}>
                        <Delete />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
            ))}
          </Grid>
        </Hidden>

        {/* Add Server Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>Add Server</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new server.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    label="Server Name"
                    value={newServerName}
                    onChange={(e) => setNewServerName(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="IP Address"
                    value={newServerIP}
                    onChange={(e) => setNewServerIP(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="User"
                    value={newServerUser}
                    onChange={(e) => setNewServerUser(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="Password"
                    value={newServerPassword}
                    onChange={(e) => setNewServerPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddServer} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Server Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Edit Server</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Update the details of the server.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    label="Server Name"
                    value={newServerName}
                    onChange={(e) => setNewServerName(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="IP Address"
                    value={newServerIP}
                    onChange={(e) => setNewServerIP(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="User"
                    value={newServerUser}
                    onChange={(e) => setNewServerUser(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label="Password"
                    value={newServerPassword}
                    onChange={(e) => setNewServerPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditServer} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
};

export default Servers;