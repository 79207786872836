import React, { createContext, useState, useMemo, useContext } from 'react';
import Cookies from 'js-cookie';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

const ThemeContext = createContext();

export const useThemeContext = () => {
    return useContext(ThemeContext);
};

export const ThemeContextProvider = ({ children }) => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [themeMode, setThemeMode] = useState(() => {
        const savedTheme = Cookies.get('theme');
        return savedTheme || (prefersDarkMode ? 'dark' : 'light');
    });

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: themeMode,
                },
                secondary: {
                    main: '#FF5722',
                    light: '#FF8A50',
                    dark: '#E64A19',
                },
            }),
        [themeMode]
    );

    const toggleTheme = () => {
        const newTheme = themeMode === 'light' ? 'dark' : 'light';
        setThemeMode(newTheme);
        Cookies.set('theme', newTheme, { expires: 365 });
    };

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};