import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    Select,
    MenuItem,
    Switch,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Card,
    CardContent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from "axios";
import API_BASE_URL from "../../apiConfig";

const DNSRecordsEditor = ({ domain, initialRecords = [], token, show = false }) => {
    const [records, setRecords] = useState([]);

    const [errors, setErrors] = useState({});
    const [copyOnTransfer, setCopyOnTransfer] = useState(domain.transfer_dns === 'on');

    useEffect(() => {
        setRecords(initialRecords.length > 0
            ? initialRecords.map((record, idx) => ({ ...record }))
            : [{ id: 1, type: 'A', name: '', content: '', proxied: false }]);
    }, [initialRecords])

    useEffect(() => {
        setRecords([]);
    }, [domain]);

    const addRecord = () => {
        const newRecord = {
            id: Date.now(),
            type: 'A',
            name: '',
            content: '',
            proxied: false,
        };
        setRecords([...records, newRecord]);
    };

    const deleteRecord = (id) => {
        setRecords(records.filter((record) => record.id !== id));
        const newErrors = { ...errors };
        delete newErrors[id];
        setErrors(newErrors);
    };

    const handleChange = (id, field, value) => {
        setRecords(
            records.map((record) =>
                record.id === id ? { ...record, [field]: value } : record
            )
        );
    };

    const validate = () => {
        const newErrors = {};
        records.forEach((record) => {
            if (!record.name) newErrors[record.id] = { ...(newErrors[record.id] || {}), name: true };
            if (!record.content) newErrors[record.id] = { ...(newErrors[record.id] || {}), content: true };
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (validate()) {
            const copyOnTransferValue = copyOnTransfer ? 'on' : 'off';
            const payload = {
                dns: records,
                copyOnTransfer: copyOnTransferValue,
            };
            console.log('Сохранённые DNS записи:', payload);

            await axios.post(`${API_BASE_URL}/api/domain_dns/${domain.id}`, payload,{
                headers: { Authorization: token },
            });

            alert('DNS записи успешно сохранены!');
        } else {
            alert('Пожалуйста, заполните все обязательные поля.');
        }
    };

    return (
        <>
        {show && (<Box>
            <Typography variant="h6" gutterBottom>
                DNS записи для {domain.domain_name}
            </Typography>
            <Box display="flex" alignItems="center" gap={2} mb={3}>
                <Typography variant="body1">Копировать при переносе</Typography>
                <Switch
                    checked={copyOnTransfer}
                    onChange={(e) => setCopyOnTransfer(e.target.checked)}
                    color="primary"
                />
            </Box>
            <Box>
                {records.map((record) => (
                    <Card key={record.id} variant="outlined" sx={{ mb: 2 }}>
                        <CardContent>
                            <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                                <FormControl variant="outlined" sx={{ width: '120px' }}>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={record.type}
                                        onChange={(e) => handleChange(record.id, 'type', e.target.value)}
                                        label="Type"
                                    >
                                        <MenuItem value="A">A</MenuItem>
                                        <MenuItem value="AAAA">AAAA</MenuItem>
                                        <MenuItem value="CNAME">CNAME</MenuItem>
                                        <MenuItem value="TXT">TXT</MenuItem>
                                        <MenuItem value="URI">URI</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Name"
                                    value={record.name}
                                    onChange={(e) => handleChange(record.id, 'name', e.target.value)}
                                    error={errors[record.id]?.name}
                                    helperText={errors[record.id]?.name ? 'Введите имя' : ''}
                                    sx={{ flex: 1, minWidth: '150px' }}
                                />
                                <TextField
                                    label="Content"
                                    value={record.content}
                                    onChange={(e) => handleChange(record.id, 'content', e.target.value)}
                                    error={errors[record.id]?.content}
                                    helperText={errors[record.id]?.content ? 'Введите контент' : ''}
                                    sx={{ flex: 2, minWidth: '200px' }}
                                />
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography variant="body2">Proxied</Typography>
                                    <Switch
                                        checked={record.proxied}
                                        onChange={(e) =>
                                            handleChange(record.id, 'proxied', e.target.checked)
                                        }
                                        color="primary"
                                    />
                                </Box>
                                <IconButton onClick={() => deleteRecord(record.id)} color="error">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    color="primary"
                    onClick={addRecord}
                >
                    Добавить запись
                </Button>
                <Button variant="contained" color="success" onClick={handleSave}>
                    Сохранить изменения
                </Button>
            </Box>
        </Box>
        )}
    </>
    );
};

const ChangeDNSDialog = ({ open, onClose, token, domain }) => {
    const [initialRecords, setInitialRecords] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!domain || !open) return;
        setShow(false);
        const fetchInit = async () => {
            try {
                await axios.get(`${API_BASE_URL}/api/domain_dns/${domain.id}`, {
                    headers: { Authorization: token },
                }).then((res) => {
                    setInitialRecords(res.data.dns || []);
                    setShow(true);
                });
            } catch (error) {
                console.error(error);
            }
        };

        fetchInit();
    }, [domain, token]);

    if (!domain) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent dividers>
                <DNSRecordsEditor domain={domain} show={show} initialRecords={initialRecords} token={token} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeDNSDialog;
