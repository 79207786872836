import React from 'react';
import { useThemeContext } from './context/ThemeContext';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

// Сам переключатель
const StyledToggle = styled(Box)(({ theme, checked }) => ({
    position: 'relative',
    width: '70px',
    height: '35px',
    borderRadius: '20px',
    background: checked
        ? 'linear-gradient(180deg, #0f2027, #203a43, #2c5364)' // Ночной градиент
        : '#87CEEB', // Чистое голубое небо
    border: checked ? '2px solid #2c5364' : '2px solid #87CEEB', // Четкие границы
    overflow: 'hidden',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    transition: 'background 0.5s ease, border 0.5s ease',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: checked ? 'calc(100% - 25px)' : '5px',
        transform: 'translateY(-50%)',
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        background: checked ? '#F0F0F0' : '#FFD700', // Полностью желтое солнце
        transition: 'left 0.5s ease, background 0.5s ease',
        boxShadow: checked
            ? '0px 2px 5px rgba(255, 255, 255, 0.5)'
            : '0px 2px 5px rgba(255, 193, 7, 0.8)',
        zIndex: 2, // Солнце над облаками
    },
    '@media (max-width: 600px)': {
        width: '60px',
        height: '30px',
        '&::before': {
            width: '20px',
            height: '20px',
            left: checked ? 'calc(100% - 20px)' : '5px',
        },
    },
}));

// Облака
const Cloud = styled(Box)(({ top, left, width, height }) => ({
    position: 'absolute',
    width: `${width}px`,
    height: `${height}px`,
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '50%',
    top: `${top}%`,
    left: `${left}%`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1, // Облака за солнцем
}));

const CloudsContainer = styled(Box)({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
    overflow: 'hidden',
});

// Звезды (оставляем для темной темы)
const Stars = styled(Box)(({ theme }) => ({
    position: 'absolute',
    width: '2px',
    height: '2px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `
        0 0 1px white, 
        8px 8px 1px white, 
        16px -8px 1px white, 
        -12px -12px 1px white, 
        -8px 8px 1px white`,
    top: '20%',
    left: '50%',
    zIndex: 1,
    '@media (max-width: 600px)': {
        boxShadow: `
            0 0 1px white, 
            6px 6px 1px white, 
            12px -6px 1px white, 
            -9px -9px 1px white, 
            -6px 6px 1px white`,
    },
}));

const ThemeToggle = () => {
    const { themeMode, toggleTheme } = useThemeContext();

    return (
        <StyledToggle onClick={toggleTheme} checked={themeMode === 'dark'}>
            {themeMode === 'light' && (
                <CloudsContainer>
                    {/* Облака с фиксированными размерами и позициями */}
                    <Cloud top={70} left={40} width={15} height={15} />
                    <Cloud top={50} left={50} width={30} height={20} />
                </CloudsContainer>
            )}
            {themeMode === 'dark' && <Stars />}
        </StyledToggle>
    );
};

export default ThemeToggle;