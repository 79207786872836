import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    DialogContentText
} from '@mui/material';

const DomainDialog = ({
                          open,
                          isEditMode,
                          domainData,          // объект домена (в editMode содержит id)
                          servers,
                          cloudflareAccounts,
                          onClose,
                          onSubmit
                      }) => {
    const [domain, setDomain] = useState({
        domain_name: '',
        file_path: '',
        type: 'main',
        transfer_type: 'auto',
        server_id: '',
        cloudflare_account_id: '',
        project_id: '',
    });

    useEffect(() => {
        if (open && domainData) {
            setDomain(domainData);
        }
    }, [open, domainData]);

    const handleDomainChange = (field, value) => {
        // При вводе доменного имени автоматически формируем file_path (только для "нового" домена?)
        let nextState = { ...domain, [field]: value };
        if (!isEditMode && field === 'domain_name') {
            nextState.file_path = `/var/www/www-root/data/www/${value}`;
        }
        setDomain(nextState);
    };

    const dialogTitle = isEditMode ? 'Редактировать домен' : 'Добавить домен';

    const handleSubmit = () => {
        onSubmit(domain);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isEditMode
                        ? 'Отредактируйте данные домена'
                        : 'Добавьте новый домен в проект'}
                </DialogContentText>

                <TextField
                    label="Домен"
                    margin="normal"
                    fullWidth
                    value={domain.domain_name || ''}
                    onChange={(e) => handleDomainChange('domain_name', e.target.value)}
                    disabled={isEditMode ? false : false} // если хотите запретить менять имя при editMode
                />

                <TextField
                    label="Путь"
                    margin="normal"
                    fullWidth
                    value={domain.file_path || ''}
                    onChange={(e) => handleDomainChange('file_path', e.target.value)}
                />

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Тип</InputLabel>
                    <Select
                        value={domain.type || 'main'}
                        onChange={(e) => handleDomainChange('type', e.target.value)}
                    >
                        <MenuItem value="main">Main</MenuItem>
                        <MenuItem value="transfer">Transfer</MenuItem>
                    </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Режим</InputLabel>
                    <Select
                        value={domain.transfer_type || 'auto'}
                        onChange={(e) => handleDomainChange('transfer_type', e.target.value)}
                    >
                        <MenuItem value="auto">Автоматический</MenuItem>
                        <MenuItem value="manual">Ручной</MenuItem>
                    </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Сервер</InputLabel>
                    <Select
                        value={domain.server_id || ''}
                        onChange={(e) => handleDomainChange('server_id', e.target.value)}
                    >
                        {servers.map((srv) => (
                            <MenuItem key={srv.id} value={srv.id}>
                                {srv.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                    <InputLabel>Cloudflare Account</InputLabel>
                    <Select
                        value={domain.cloudflare_account_id || ''}
                        onChange={(e) => handleDomainChange('cloudflare_account_id', e.target.value)}
                    >
                        <MenuItem value="">Нет</MenuItem>
                        {cloudflareAccounts.map((cf) => (
                            <MenuItem key={cf.id} value={cf.id}>
                                {cf.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit} variant="contained">
                    {isEditMode ? 'Сохранить' : 'Добавить'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DomainDialog;
