import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Box, IconButton, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import API_BASE_URL from '../../../apiConfig';

const TableManager = ({ token }) => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [newRowData, setNewRowData] = useState({});
  const [error, setError] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);

  useEffect(() => {
    fetchTables();
  }, [token]);

  useEffect(() => {
    if (selectedTable) {
      fetchColumns();
      fetchRows();
    }
  }, [selectedTable]);

  const fetchTables = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tables`, {
        headers: { Authorization: token }
      });
      setTables(response.data.data);
    } catch (error) {
      setError('Failed to fetch tables');
    }
  };

  const fetchColumns = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/columns/${selectedTable}`, {
        headers: { Authorization: token }
      });
      setColumns(response.data.data);
    } catch (error) {
      setError('Failed to fetch columns');
    }
  };

  const fetchRows = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tables/${selectedTable}`, {
        headers: { Authorization: token }
      });
      setRows(response.data.data);
    } catch (error) {
      setError('Failed to fetch rows');
    }
  };

  const handleOpenAddDialog = () => {
    setNewRowData({});
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddRow = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/tables/${selectedTable}`, newRowData, {
        headers: { Authorization: token }
      });
      fetchRows();
      handleCloseAddDialog();
    } catch (error) {
      setError('Failed to add row');
    }
  };

  const handleDeleteRow = async (rowId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/tables/${selectedTable}/${rowId}`, {
        headers: { Authorization: token }
      });
      fetchRows();
    } catch (error) {
      setError('Failed to delete row');
    }
  };

  const handleEditRow = (row) => {
    setNewRowData(row);
    setOpenAddDialog(true);
  };

  const handleInputChange = (e, columnName) => {
    setNewRowData({
      ...newRowData,
      [columnName]: e.target.value,
    });
  };

  return (
    <Container>
      {error && <Typography color="error">{error}</Typography>}
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Table</InputLabel>
        <Select
          value={selectedTable}
          onChange={(e) => setSelectedTable(e.target.value)}
        >
          {tables.map((table) => (
            <MenuItem key={table.name} value={table.name}>
              {table.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedTable && (
        <>
          <Box display="flex" justifyContent="flex-end" marginBottom={2}>
            <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
              Add Row
            </Button>
          </Box>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.name}>{column.name}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.name}>{row[column.name]}</TableCell>
                    ))}
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEditRow(row)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteRow(row.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
            <DialogTitle>{newRowData.id ? 'Edit Row' : 'Add Row'}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {newRowData.id ? 'Edit the details of the row.' : 'Enter the details of the new row.'}
              </DialogContentText>
              {columns.map((column) => (
                <TextField
                  key={column.name}
                  label={column.name}
                  value={newRowData[column.name] || ''}
                  onChange={(e) => handleInputChange(e, column.name)}
                  margin="normal"
                  fullWidth
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddRow} color="primary">
                {newRowData.id ? 'Save' : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default TableManager;
