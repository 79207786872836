import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import API_BASE_URL from '../../apiConfig';

const DomainTransferHistoryDialog = ({ open, onClose, token, domain }) => {
    const [history, setHistory] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)'); // Проверяем, является ли экран мобильным

    useEffect(() => {
        if (open && domain?.id) {
            setHistory([]);
            const fetchHistory = async () => {
                try {
                    const res = await axios.get(
                        `${API_BASE_URL}/api/domain_transfer_history/${domain.id}`,
                        { headers: { Authorization: token } }
                    );
                    setHistory(res.data.data);
                } catch (err) {
                    console.error('Failed to fetch domain transfer history:', err);
                }
            };
            fetchHistory();
        }
    }, [open, domain, token]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>История переездов: {domain?.domain_name}</DialogTitle>
            <DialogContent>
                {isMobile ? (
                    // Мобильная версия — карточки
                    <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                        <Grid container spacing={2}>
                            {history.map((record) => (
                                <Grid item xs={12} sm={6} md={4} key={record.id}>
                                    <Card variant="outlined" sx={{ height: '100%' }}>
                                        <CardContent>
                                            <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                                                ID: {record.id}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                <strong>Start Domain:</strong> {record.main_domain_name}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                <strong>End Domain:</strong> {record.transfer_domain_name}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                <strong>Action:</strong> {record.action}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary">
                                                <strong>Date:</strong> {new Date(record.created_at).toLocaleString()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    // Десктопная версия — таблица
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Start Domain</TableCell>
                                <TableCell>End Domain</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map((record) => (
                                <TableRow key={record.id}>
                                    <TableCell>{record.id}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{record.main_domain_name}</TableCell>
                                    <TableCell>{record.transfer_domain_name}</TableCell>
                                    <TableCell>{record.action}</TableCell>
                                    <TableCell>{new Date(record.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DomainTransferHistoryDialog;