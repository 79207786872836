import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import API_BASE_URL from '../../../apiConfig';

const Admin = ({ token }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [telegram, setTelegram] = useState('');
  const [role, setRole] = useState('user');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/users`, {
        login, password, telegram, role
      }, {
        headers: {
          'Authorization': token
        }
      });
      setMessage(`User created with ID: ${response.data.id}`);
    } catch (error) {
      setMessage('Error creating user');
    }
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <TextField
          label="Login"
          variant="outlined"
          margin="normal"
          fullWidth
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Telegram"
          variant="outlined"
          margin="normal"
          fullWidth
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
        />
        <TextField
          label="Role"
          variant="outlined"
          margin="normal"
          fullWidth
          value={role}
          disabled
          onChange={(e) => setRole(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Create
        </Button>
      </form>
      {message && <Alert severity="success">{message}</Alert>}
    </Container>
  );
};

export default Admin;
