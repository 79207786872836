import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button
} from '@mui/material';

const CreateProjectDialog = ({
                                 open,
                                 onClose,
                                 newProjectName,
                                 setNewProjectName,
                                 onCreateProject
                             }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Создать новый проект</DialogTitle>
            <DialogContent>
                <TextField
                    label="Название проекта"
                    fullWidth
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={onCreateProject} variant="contained">
                    Создать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateProjectDialog;
