import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button
} from '@mui/material';

const EditProjectDialog = ({
                               open,
                               onClose,
                               editProjectName,
                               setEditProjectName,
                               onEditProject
                           }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Редактировать проект</DialogTitle>
            <DialogContent>
                <TextField
                    label="Название проекта"
                    fullWidth
                    value={editProjectName}
                    onChange={(e) => setEditProjectName(e.target.value)}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={onEditProject} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditProjectDialog;
